html.wip-image-zoom-immersive-mode-enabled {
  overflow: hidden;
}

html body .immersive-wip-image-zoom {
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
}

html body .immersive-wip-image-zoom > .disable-immersive-mode-button {
  position: fixed;
  top: 0;
  right: 0;
  padding: 8px;
  font-size: 24px;
  line-height: 1;
  cursor: pointer;
  z-index: 20;
}

html body .immersive-wip-image-zoom > .wip-image-zoom:not(.zoom-disabled) {
  z-index: 20;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  left: 0;
  right: 0;
  width: 75vh;
  max-width: 75vw;
  margin: auto;
}

html body .immersive-wip-image-zoom > .wip-image-zoom.zoom-disabled {
  overflow: auto;
  height: 100%;
  padding: 32px;
  box-sizing: border-box;
}

html body .immersive-wip-image-zoom .immersive-no-zoom-image-wrapper > img {
  min-width: 100%;
  width: 100%;
  display: block;
}

.wip-image-zoom {
  display: block;
  max-width: 100%;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.wip-image-zoom .main-image-wrapper {
  position: relative;
  overflow: hidden;
}

.wip-image-zoom .main-image-wrapper.loading .image-zoom-tracker {
  cursor: wait !important;
}

.wip-image-zoom .main-image-wrapper.loading .main-image {
  z-index: 28 !important;
}

.wip-image-zoom .main-image-wrapper .image-zoom-lens {
  display: none;
  position: absolute;
  z-index: 25;
  height: 100px;
  width: 100px;
  background: transparent;
  border: 2px solid #FFFFFF;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.55), 0 0 0 1px rgba(0, 0, 0, 0.55);
  box-sizing: border-box;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity .3s ease, visibility .3s ease;
  transition: opacity .3s ease, visibility .3s ease;
}

.wip-image-zoom .main-image-wrapper .image-zoom-tracker {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 30;
}

.wip-image-zoom .main-image-wrapper .zoom-mask {
  background: #ffffff;
  z-index: 20;
  position: absolute;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity .3s ease, visibility .3s ease;
  transition: opacity .3s ease, visibility .3s ease;
}

.wip-image-zoom .main-image-wrapper .zoom-mask .zoom-image {
  max-width: none;
}

.wip-image-zoom .main-image-wrapper .immersive-mode-message {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 12px;
  font-weight: 400;
  padding: 6px 8px;
  margin-bottom: 12px;
  z-index: 20;
  text-align: center;
}

.wip-image-zoom .main-image {
  position: relative;
  display: block;
  z-index: 10;
  width: 100%;
}

.wip-image-zoom.box-style .zoom-mask {
  border: 2px solid rgba(0, 0, 0, 0.55);
  box-sizing: border-box;
}

.wip-image-zoom.box-style .zoom-mask.right-top {
  top: 0;
  left: 100%;
  right: auto;
}

.wip-image-zoom.box-style .zoom-mask.right-middle {
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  left: 100%;
  right: auto;
}

.wip-image-zoom.box-style .zoom-mask.right-bottom {
  bottom: 0;
  left: 100%;
  right: auto;
}

.wip-image-zoom.box-style .zoom-mask.left-top {
  top: 0;
  right: 100%;
  left: auto;
}

.wip-image-zoom.box-style .zoom-mask.left-middle {
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 100%;
  left: auto;
}

.wip-image-zoom.box-style .zoom-mask.left-bottom {
  bottom: 0;
  right: 100%;
  left: auto;
}

.wip-image-zoom.box-style .zoom-mask.bottom-left {
  left: 0;
  top: 100%;
  bottom: auto;
}

.wip-image-zoom.box-style .zoom-mask.bottom-center {
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  top: 100%;
  bottom: auto;
}

.wip-image-zoom.box-style .zoom-mask.bottom-right {
  right: 0;
  top: 100%;
  bottom: auto;
}

.wip-image-zoom.box-style .zoom-mask.top-left {
  left: 0;
  bottom: 100%;
  top: auto;
}

.wip-image-zoom.box-style .zoom-mask.top-center {
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  bottom: 100%;
  top: auto;
}

.wip-image-zoom.box-style .zoom-mask.top-right {
  right: 0;
  bottom: 100%;
  top: auto;
}

.wip-image-zoom.top-thumbs wip-image-zoom-thumbs, .wip-image-zoom.bottom-thumbs wip-image-zoom-thumbs {
  position: relative;
  display: block;
}

.wip-image-zoom.top-thumbs wip-image-zoom-thumbs .thumbs-wrapper, .wip-image-zoom.bottom-thumbs wip-image-zoom-thumbs .thumbs-wrapper {
  width: 100%;
  overflow: hidden;
}

.wip-image-zoom.top-thumbs wip-image-zoom-thumbs .thumbs-wrapper .thumbs, .wip-image-zoom.bottom-thumbs wip-image-zoom-thumbs .thumbs-wrapper .thumbs {
  display: inline-block;
  white-space: nowrap;
  -webkit-transition: -webkit-transform 300ms ease;
  transition: -webkit-transform 300ms ease;
  transition: transform 300ms ease;
  transition: transform 300ms ease, -webkit-transform 300ms ease;
}

.wip-image-zoom.top-thumbs wip-image-zoom-thumbs .thumbs-wrapper .thumbs .thumb-wrapper, .wip-image-zoom.bottom-thumbs wip-image-zoom-thumbs .thumbs-wrapper .thumbs .thumb-wrapper {
  display: inline-block;
  box-sizing: border-box;
}

.wip-image-zoom.top-thumbs wip-image-zoom-thumbs .thumbs-wrapper .thumbs .thumb-wrapper > img, .wip-image-zoom.bottom-thumbs wip-image-zoom-thumbs .thumbs-wrapper .thumbs .thumb-wrapper > img {
  display: block;
  width: 100%;
  cursor: pointer;
}

.wip-image-zoom.top-thumbs wip-image-zoom-thumbs .thumbs-wrapper .thumbs .thumb-wrapper > img.selected, .wip-image-zoom.bottom-thumbs wip-image-zoom-thumbs .thumbs-wrapper .thumbs .thumb-wrapper > img.selected {
  box-shadow: 0 2px 0 0 #9C0033;
}

.wip-image-zoom.top-thumbs wip-image-zoom-thumbs .prev-button,
.wip-image-zoom.top-thumbs wip-image-zoom-thumbs .next-button, .wip-image-zoom.bottom-thumbs wip-image-zoom-thumbs .prev-button,
.wip-image-zoom.bottom-thumbs wip-image-zoom-thumbs .next-button {
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  cursor: pointer;
  padding: 8px;
}

.wip-image-zoom.top-thumbs wip-image-zoom-thumbs .next-button, .wip-image-zoom.bottom-thumbs wip-image-zoom-thumbs .next-button {
  left: 100%;
}

.wip-image-zoom.top-thumbs wip-image-zoom-thumbs .prev-button, .wip-image-zoom.bottom-thumbs wip-image-zoom-thumbs .prev-button {
  right: 100%;
}

.wip-image-zoom.left-thumbs .main-image-wrapper, .wip-image-zoom.right-thumbs .main-image-wrapper {
  width: 80%;
  float: left;
}

.wip-image-zoom.left-thumbs wip-image-zoom-thumbs, .wip-image-zoom.right-thumbs wip-image-zoom-thumbs {
  width: 20%;
  float: left;
}

.wip-image-zoom.left-thumbs wip-image-zoom-thumbs .thumbs-wrapper, .wip-image-zoom.right-thumbs wip-image-zoom-thumbs .thumbs-wrapper {
  height: 100%;
  overflow: hidden;
}

.wip-image-zoom.left-thumbs wip-image-zoom-thumbs .thumbs-wrapper .thumbs .thumb-wrapper, .wip-image-zoom.right-thumbs wip-image-zoom-thumbs .thumbs-wrapper .thumbs .thumb-wrapper {
  width: 100% !important;
}

.wip-image-zoom.left-thumbs wip-image-zoom-thumbs .thumbs-wrapper .thumbs .thumb-wrapper > img, .wip-image-zoom.right-thumbs wip-image-zoom-thumbs .thumbs-wrapper .thumbs .thumb-wrapper > img {
  width: 100%;
  display: block;
}

.wip-image-zoom.inner-style .zoom-mask {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.wip-image-zoom.active .main-image-wrapper:not(.loading) {
  overflow: visible;
}

.wip-image-zoom.active .main-image-wrapper:not(.loading) .zoom-mask, .wip-image-zoom.active .main-image-wrapper:not(.loading) .image-zoom-lens {
  opacity: 1;
  visibility: visible;
}

.wip-image-zoom.immersive-mode .main-image-wrapper {
  overflow: hidden;
}

.wip-image-zoom.immersive-mode .main-image-wrapper .zoom-mask, .wip-image-zoom.immersive-mode .main-image-wrapper .image-zoom-lens {
  opacity: 0;
  visibility: hidden;
}

.wip-image-zoom.immersive-mode .main-image-wrapper .image-zoom-tracker {
  cursor: zoom-in !important;
}
